import { ATTR } from "./consts";
const CMPS = 4 + 2;
const STRIDE = 4 * CMPS;
export class Billboards {
    constructor(gl, count) {
        this.verts = new Float32Array(count * 4 * CMPS);
        this.idxs = new Uint16Array(count * 6);
        this.vert_buffer = gl.createBuffer();
        this.idx_buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vert_buffer);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.idx_buffer);
        for (let i = 0; i < count; i++) {
            this.set_uv(i, 0, 0, 1, 1);
        }
        gl.bufferData(gl.ARRAY_BUFFER, this.verts, gl.STREAM_DRAW);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.idxs, gl.STREAM_DRAW);
    }
    bind(gl, shader) {
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vert_buffer);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.idx_buffer);
        for (const a of [
            { attr: ATTR.POS, count: 4, ofs: 4 * 0 },
            { attr: ATTR.UV, count: 2, ofs: 4 * (4) },
        ]) {
            const loc = shader.attrib_locations.get(a.attr);
            if (loc != -1) {
                gl.vertexAttribPointer(loc, a.count, gl.FLOAT, false, STRIDE, a.ofs);
            }
        }
    }
    set_on(i, on) {
        const idxs = this.idxs;
        const i_ofs = i * 6;
        const v_ofs = i * 4;
        function set_v(i, v) {
            idxs[i_ofs + i] = v_ofs + v;
        }
        if (on) {
            set_v(0, 0);
            set_v(1, 2);
            set_v(2, 1);
            set_v(3, 0);
            set_v(4, 3);
            set_v(5, 2);
        }
        else {
            set_v(0, 0);
            set_v(1, 0);
            set_v(2, 0);
            set_v(3, 0);
            set_v(4, 0);
            set_v(5, 0);
        }
    }
    set_char(i, l, t, r, b, char, opacity) {
        const verts = this.verts;
        function set_vert(j, x, y) {
            const ofs = i * STRIDE + CMPS * j;
            verts[ofs + 0] = x * 2.0 / 800.0 - 1.0;
            verts[ofs + 1] = y * 2.0 / 600.0 - 1.0;
            verts[ofs + 2] = 1.0;
            verts[ofs + 3] = opacity;
        }
        set_vert(0, l, t);
        set_vert(1, r, t);
        set_vert(2, r, b);
        set_vert(3, l, b);
        const char_x = char % 16;
        const char_y = Math.floor(char / 16);
        this.set_uv(i, (char_x) * 10 / 256.0, (char_y) * 12 / 256.0, (char_x + 1) * 10 / 256.0, (char_y + 1) * 12 / 256.0);
    }
    /// Sets position for a whole billboard
    set_pos(i, pos, opacity) {
        const verts = this.verts;
        for (let j = 0; j < 4; j++) {
            const ofs = i * STRIDE + CMPS * j;
            verts[ofs + 0] = pos[0];
            verts[ofs + 1] = pos[1];
            verts[ofs + 2] = pos[2];
            verts[ofs + 3] = opacity;
        }
    }
    set_uv(i, l, t, r, b) {
        const ofs = i * STRIDE;
        const verts = this.verts;
        function set_v(i, x, y) {
            verts[ofs + CMPS * i + 4] = x;
            verts[ofs + CMPS * i + 5] = y;
        }
        // Set up UV coords
        set_v(0, l, t);
        set_v(1, r, t);
        set_v(2, r, b);
        set_v(3, l, b);
    }
    upload(gl) {
        gl.bufferData(gl.ARRAY_BUFFER, this.verts, gl.STREAM_DRAW);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.idxs, gl.STREAM_DRAW);
    }
    draw(gl, count) {
        gl.drawElements(gl.TRIANGLES, count * 6, gl.UNSIGNED_SHORT, 0);
    }
}
