class RsKey {
    constructor() {
        this.down = false;
        this.just_pressed = false;
    }
    down_or_just_pressed() {
        return this.down || this.just_pressed;
    }
    keydown() {
        this.just_pressed = true;
        this.down = true;
    }
    keyup() {
        this.down = false;
    }
    tick() {
        this.just_pressed = false;
    }
}
export class RsGamepad {
    constructor() {
        this.clear();
    }
    clear() {
        this.d_left = new RsKey();
        this.d_right = new RsKey();
        this.d_up = new RsKey();
        this.d_down = new RsKey();
        this.action_1 = new RsKey();
        this.action_2 = new RsKey();
    }
    decode(code) {
        if (code == "ArrowDown") {
            return this.d_down;
        }
        else if (code == "ArrowUp") {
            return this.d_up;
        }
        if (code == "ArrowLeft") {
            return this.d_left;
        }
        else if (code == "ArrowRight") {
            return this.d_right;
        }
        else if (code == "Space") {
            return this.action_1;
        }
        else if (code == "ControlLeft") {
            return this.action_1;
        }
        else if (code == "ShiftLeft") {
            return this.action_2;
        }
        else if (code == "ShiftRight") {
            return this.action_2;
        }
        else if (code == "KeyS") {
            return this.d_down;
        }
        else if (code == "KeyW") {
            return this.d_up;
        }
        else if (code == "KeyA") {
            return this.d_left;
        }
        else if (code == "KeyD") {
            return this.d_right;
        }
        else if (code == "KeyK") {
            return this.d_down;
        }
        else if (code == "KeyI") {
            return this.d_up;
        }
        else if (code == "KeyJ") {
            return this.d_left;
        }
        else if (code == "KeyL") {
            return this.d_right;
        }
        else {
            console.log("Unknown keycode " + code);
            return null;
        }
    }
    keydown(code) {
        const key = this.decode(code);
        if (key != null) {
            key.keydown();
        }
    }
    keyup(code) {
        const key = this.decode(code);
        if (key != null) {
            key.keyup();
        }
    }
    tick() {
        this.d_left.tick();
        this.d_right.tick();
        this.d_up.tick();
        this.d_down.tick();
        this.action_1.tick();
        this.action_2.tick();
    }
}
