import { SCENE } from "./consts";
import { Mat4, Vec3 } from "./matrix";
import { fixed_to_float, } from "./fixed_point";
import { println } from "./print";
import * as RandomThings from "./random_things";
export const state_editing = "1 editing";
export const state_racing = "2 racing";
export const state_menu = "3 menu";
const playing_countdown = "playing_countdown";
const playing_racing = "playing_racing";
export class SoundEffectTriggers {
    constructor() {
        this.sound_blip = false;
        this.sound_coin = false;
        this.sound_ding = false;
    }
}
function load_track(encoded) {
    const s = window.atob(encoded);
    const version = s.charCodeAt(0);
    if (version != 2) {
        alert("Unexpected track version");
    }
    let cursor = 2;
    let read_float = function () {
        const x = fixed_to_float([s.charCodeAt(cursor + 0), s.charCodeAt(cursor + 1)]);
        cursor += 2;
        return x;
    };
    const track = new Array();
    while (cursor < s.length) {
        const x = read_float();
        const y = read_float();
        track.push([x, y]);
    }
    return track;
}
class PlayingState {
    constructor() {
        this.track = [];
        this.next_waypoint = 1;
        this.shrubs = [];
        this.state = playing_countdown;
        this.countdown_timer = -5 * 60;
        this.lap_time = 0;
        this.best_lap_time = null;
        this.track_square_mat_cache_inner = [];
        this.track_square_mat_cache_outer = [];
    }
    open(encoded) {
    }
    step(gamepad, sfx_triggers) {
    }
}
class BoxShape3D {
}
class Physics {
    constructor(pos) {
        this.half_extents = Vec3.create();
        this.is_particle = false;
        this.g_force_kill = 1000000.0;
        this.prev_pos = Vec3.create();
        this.pos = Vec3.create();
        this.vel = Vec3.create();
        this.inv_mass = 1.0;
        this.mu = 0.2;
        this.prev_pos.set_to(pos);
        this.pos.set_to(pos);
    }
}
class Bomb {
    constructor() {
        this.live_until_frame = 0;
    }
}
class Blood {
    constructor() {
        this.live_until_frame = 0;
    }
}
class Edible {
}
class Explosion {
    constructor() {
        this.start_frame = 0;
        this.live_until_frame = 0;
    }
}
class Renderable {
    constructor() {
        this.name = "";
        this.base_transform = Mat4.create();
        this.transform = Mat4.create();
    }
}
class Grabable {
    constructor() {
        this.grabbed = false;
    }
}
class TofuTrooper {
    constructor() {
        this.reload_timer = 0;
    }
}
class Ecs {
    constructor() {
        this.frame = 0;
        this.last_ent = 0;
        this.bloods = new Map();
        this.bombs = new Map();
        this.edibles = new Map();
        this.explosions = new Map();
        this.gibables = new Map();
        this.grabables = new Map();
        this.physics = new Map();
        this.renderables = new Map();
        this.tofu_troopers = new Map();
    }
    del_ent(ent) {
        for (const m of [
            this.bloods,
            this.bombs,
            this.edibles,
            this.explosions,
            this.gibables,
            this.grabables,
            this.physics,
            this.renderables,
            this.tofu_troopers,
        ]) {
            m.delete(ent);
        }
    }
    new_ent() {
        this.last_ent += 1;
        return this.last_ent;
    }
    explode_bomb(bomb_ent) {
        const bomb = this.bombs.get(bomb_ent);
        const bomb_phys = this.physics.get(bomb_ent);
        const expl_ent = this.new_ent();
        {
            const x = new Explosion();
            x.start_frame = this.frame;
            x.live_until_frame = this.frame + 45;
            this.explosions.set(expl_ent, x);
        }
        {
            const x = new Physics(bomb_phys.pos);
            x.half_extents = new Vec3([0.5, 0.5, 0.5]);
            // x.inv_mass = 0.0;
            x.is_particle = true;
            x.vel = bomb_phys.vel.scaled(0.5);
            this.physics.set(expl_ent, x);
        }
        this.del_ent(bomb_ent);
        // Destroy everything in a radius
        for (const [gib_ent, _] of [...this.gibables]) {
            const gib_phys = this.physics.get(gib_ent);
            const diff = gib_phys.pos.sub(bomb_phys.pos);
            const len_sq = diff.length_squared();
            if (len_sq > 0.75 * 0.75) {
                continue;
            }
            let direction = Vec3.create();
            if (len_sq > 0.0625) {
                direction = diff.scaled(4.0 / Math.sqrt(len_sq));
            }
            this.kersplode(gib_ent, direction);
        }
    }
    kersplode(ent, offset_vel) {
        const ent_p = this.physics.get(ent);
        this.spawn_blood(ent_p.pos, ent_p.vel.add(offset_vel));
        this.del_ent(ent);
    }
    spawn_blood(pos, base_vel) {
        for (let i = 0; i < 16; i++) {
            const e = this.new_ent();
            const b = new Blood();
            b.live_until_frame = this.frame + 240;
            const p = new Physics(pos);
            p.is_particle = true;
            p.mu = 20.0;
            p.vel = base_vel.add(RandomThings.point_in_sphere());
            const r = 0.04;
            p.half_extents = new Vec3([r, r, r]);
            p.inv_mass = 100.0;
            this.bloods.set(e, b);
            this.physics.set(e, p);
        }
    }
    mark_edible(ent) {
        const x = new Edible();
        this.edibles.set(ent, x);
        return x;
    }
    mark_grabable(ent) {
        const x = new Grabable();
        this.grabables.set(ent, x);
        return x;
    }
    mark_physics(ent, node, scale, half_extents) {
        const x = new Physics(node.transform.multiply_vec3(new Vec3([0.0, 0.0, 0.0]), 1.0));
        x.half_extents = new Vec3([
            half_extents[0] * scale,
            half_extents[1] * scale,
            half_extents[2] * scale
        ]);
        this.physics.set(ent, x);
        return x;
    }
}
class Digesting {
    constructor() {
        this.timer = 100;
        this.timer_max = 100;
    }
}
class Giantess {
    constructor() {
        this.bubbles = 0.0;
        this.camera_blend = 0.0;
        this.digesting = null;
        this.grabbed_ent = null;
        this.pos = Vec3.create();
    }
}
/// Frame context for physics
class FrameCtx {
    constructor() {
        this.gravity = new Vec3();
        this.h = null;
        this.g_forces = new Map();
    }
}
class Collision {
}
const aabb_vecs = [
    new Vec3([-1, 0, 0]),
    new Vec3([1, 0, 0]),
    new Vec3([0, -1, 0]),
    new Vec3([0, 1, 0]),
    new Vec3([0, 0, -1]),
    new Vec3([0, 0, 1]),
];
class MenuState {
    constructor(resources) {
        this.phys_mpf = 0.0;
        this.theta = 0.0 * Math.PI;
        this.gts = new Giantess();
        this.t = 0.0;
        this.boxes = [];
        this.ecs = new Ecs();
        const scene = resources.scenes.get(SCENE.CLIFFSIDE);
        // Make the ground plane a big AABB
        {
            const ent = this.ecs.new_ent();
            const p = new Physics(new Vec3([0.0, -1.0, 0.0]));
            p.half_extents = new Vec3([100.0, 1.0, 10.0]);
            p.inv_mass = 0.0;
            // The ground is very grippy
            p.mu = 1.0;
            this.ecs.physics.set(ent, p);
        }
        for (const node of scene.nodes) {
            const inst = node.instance;
            if (!scene.ext_resources.has(inst)) {
                continue;
            }
            const res = scene.ext_resources.get(inst);
            const ent = this.ecs.new_ent();
            const r = new Renderable();
            r.gltf = res.gltf_name;
            r.name = node.name;
            r.texture = `textures/${res.gltf_name}.png`;
            r.transform = node.transform;
            this.ecs.renderables.set(ent, r);
            if (inst == "5_7dgm2") {
                // Truck
                const p = this.ecs.mark_physics(ent, node, 0.5, [1.09, 0.55, 0.481]);
                p.g_force_kill = 12.0;
                this.ecs.mark_grabable(ent);
            }
            else if (inst == "5_m3yp5") {
                // Civilian
                const p = this.ecs.mark_physics(ent, node, 0.027, [2.0, 6.5, 2.0]);
                p.g_force_kill = 5.0;
                p.inv_mass = 10.0;
                this.ecs.mark_edible(ent);
            }
            else if (inst == "6_25oic") {
                this.ecs.tofu_troopers.set(ent, new TofuTrooper());
                const p = this.ecs.mark_physics(ent, node, 0.079, [2.0, 2.7, 1.2]);
                p.g_force_kill = 10.0;
                p.inv_mass = 5.0;
                this.ecs.mark_edible(ent);
            }
            else if (inst == "6_hyssy" || inst == "8_mlqhj") {
                // Cube house or pointy house
                const p = this.ecs.mark_physics(ent, node, 1.0, [1.0, 1.0, 1.0]);
                p.inv_mass = 0.5;
            }
        }
        // Any renderable with physics will need its base_transform fixed
        for (const [ent, p] of [...this.ecs.physics]) {
            if (this.ecs.renderables.has(ent)) {
                const r = this.ecs.renderables.get(ent);
                r.base_transform = r.transform.translate(p.pos.inverted());
            }
        }
        // Any edible is implicitly gibable and grabable
        for (const [ent, p] of [...this.ecs.edibles]) {
            this.ecs.gibables.set(ent, true);
            if (!this.ecs.grabables.has(ent)) {
                this.ecs.mark_grabable(ent);
            }
        }
        // Do a few physics steps with no gravity to just pop stuff
        // out of the ground and warm-start everything before the graphics
        // can see any glitches.
        if (true) {
            const num_substeps = 20;
            const dt = 1.0 / 60.0;
            const ctx = new FrameCtx();
            ctx.gravity = new Vec3([0.0, -20.0, 0.0]);
            ctx.h = dt / num_substeps;
            const pairs = this.collect_collision_pairs(dt);
            for (let i = 0; i < num_substeps; i++) {
                this.physics_substep(ctx, pairs);
            }
            this.update_renderables_from_physics();
        }
    }
    update_renderables_from_physics() {
        for (const [ent, p] of [...this.ecs.physics]) {
            if (this.ecs.renderables.has(ent)) {
                const r = this.ecs.renderables.get(ent);
                r.transform = r.base_transform.translate(p.pos);
            }
        }
    }
    step(game_state, gamepad, sfx_triggers) {
        const gts = this.gts;
        const turn_speed = 8.0 / 256.0;
        const walk_speed = 16.0 / 256.0;
        const move = [0.0, 0.0];
        if (gamepad.d_left.down_or_just_pressed()) {
            move[0] -= 1.0;
        }
        if (gamepad.d_right.down_or_just_pressed()) {
            move[0] += 1.0;
        }
        if (gamepad.d_up.down_or_just_pressed()) {
            move[1] -= 1.0;
        }
        if (gamepad.d_down.down_or_just_pressed()) {
            move[1] += 1.0;
        }
        const move_len_sq = move[0] * move[0] + move[1] * move[1];
        if (move_len_sq > 1.0) {
            const move_len = Math.sqrt(move_len_sq);
            move[0] = move[0] / move_len;
            move[1] = move[1] / move_len;
        }
        gts.pos[0] += walk_speed * move[0];
        gts.pos[2] += walk_speed * move[1];
        // Collision
        let colliding = false;
        for (const box of this.boxes) {
            if (gts.pos[0] > box.transform[12] + 1) {
            }
            else if (gts.pos[0] < box.transform[12] - 1) {
            }
            else if (gts.pos[2] > box.transform[14] + 1) {
            }
            else if (gts.pos[2] < box.transform[14] - 1) {
            }
            else {
                colliding = true;
            }
        }
        if (colliding) {
            gts.pos[1] = 2.0;
        }
        else {
            gts.pos[1] = 0.0;
        }
        // Try to grab stuff
        if (gamepad.action_1.just_pressed) {
            this.do_action_1();
        }
        // Try to throw / swallow stuff
        if (gamepad.action_2.just_pressed) {
            this.do_action_2();
        }
        // Update physics
        {
            const before = performance.now();
            const num_substeps = 10;
            const dt = 1.0 / 60.0;
            const ctx = new FrameCtx();
            ctx.gravity = new Vec3([0.0, -20.0, 0.0]);
            ctx.h = dt / num_substeps;
            const pairs = this.collect_collision_pairs(dt);
            for (let i = 0; i < num_substeps; i++) {
                this.physics_substep(ctx, pairs);
            }
            const after = performance.now();
            this.phys_mpf += after - before;
        }
        // Giantess digestion and such
        const digestion_rate = 1.0 / 8.0;
        const bubble_rate = 17.0 * digestion_rate / 100.0;
        if (gts.digesting != null) {
            const d = gts.digesting;
            d.timer -= digestion_rate;
            gts.bubbles = Math.min(16, gts.bubbles + bubble_rate);
            if (d.timer <= 0) {
                gts.digesting = null;
            }
        }
        else {
            gts.bubbles = Math.max(0, gts.bubbles -= 17.0 / 100.0);
        }
        const camera_titan_rate = 1.0 / 30.0;
        const camera_slow_widen = 1.0 / 90.0;
        if ((gts.grabbed_ent != null && this.ecs.edibles.has(gts.grabbed_ent))) {
            // Holding someone - Tighten camera fast
            gts.camera_blend = Math.min(1.0, gts.camera_blend + camera_titan_rate);
        }
        else if (gts.digesting != null) {
            // Digesting someone - Widen camera slowly
            gts.camera_blend = Math.max(0.0, gts.camera_blend - camera_slow_widen);
        }
        else {
            // Not holding anyone - Widen camera fast 
            gts.camera_blend = Math.max(0.0, gts.camera_blend - camera_titan_rate);
        }
        // Explosions
        for (const [ent, expl] of [...this.ecs.explosions]) {
            if (this.ecs.frame >= expl.live_until_frame) {
                this.ecs.del_ent(ent);
            }
        }
        // Bombs
        for (const [ent, bomb] of [...this.ecs.bombs]) {
            if (this.ecs.frame < bomb.live_until_frame) {
                continue;
            }
            this.ecs.explode_bomb(ent);
        }
        // Tofu troopers
        for (const [ent, tofu] of [...this.ecs.tofu_troopers]) {
            // If Cerulean grabs a tofu trooper, he stops firing and resets his timer
            const tofu_grabable = this.ecs.grabables.get(ent);
            if (tofu_grabable.grabbed) {
                tofu.reload_timer = 60;
                continue;
            }
            if (tofu.reload_timer > 0) {
                tofu.reload_timer -= 1;
                continue;
            }
            // Fire!
            tofu.reload_timer = 60;
            const tofu_p = this.ecs.physics.get(ent);
            const radius = 0.125;
            const bullet_ent = this.ecs.new_ent();
            {
                const x = new Bomb();
                x.live_until_frame = this.ecs.frame + 90;
                this.ecs.bombs.set(bullet_ent, x);
            }
            {
                const start_dist = 0.25;
                const bullet_p = new Physics(tofu_p.pos.add(new Vec3([-start_dist, start_dist, 0.0])));
                bullet_p.half_extents = new Vec3([radius, radius, radius]);
                bullet_p.vel[0] = -5.0;
                bullet_p.vel[1] = 5.0;
                // Bullets are very grippy
                bullet_p.mu = 0.5;
                bullet_p.inv_mass = 20.0;
                this.ecs.physics.set(bullet_ent, bullet_p);
            }
            {
                const r = new Renderable();
                r.base_transform = r.base_transform.scale_1(radius);
                r.gltf = "pomegranate";
                r.texture = "textures/pomegranate.png";
                this.ecs.renderables.set(bullet_ent, r);
            }
        }
        this.update_renderables_from_physics();
        // Update blood particles
        for (const [ent, b] of [...this.ecs.bloods]) {
            if (this.ecs.frame >= b.live_until_frame) {
                this.ecs.del_ent(ent);
                continue;
            }
        }
        this.ecs.frame += 1;
    }
    collect_collision_pairs(dt) {
        const pairs = new Array();
        const physics = this.ecs.physics;
        const keys = Array.from(physics.keys());
        const margins = new Array();
        const k = 2.0;
        const margin_factor = k * dt;
        // Couldn't get this to work, didn't end up needing it
        const gravity_margin = 0.0 * dt * dt;
        for (let u = 0; u < keys.length; u++) {
            const i = keys[u];
            const p_i = physics.get(i);
            margins.push(margin_factor * p_i.vel.vec_length() + gravity_margin);
        }
        for (let u = 0; u < keys.length; u++) {
            const i = keys[u];
            const p_i = physics.get(i);
            for (let v = u + 1; v < keys.length; v++) {
                const j = keys[v];
                const p_j = physics.get(j);
                const margin = margins[u] + margins[v];
                const c = this.detect_collision(p_i, p_j, margin);
                if (c == null) {
                    continue;
                }
                pairs.push([i, j]);
            }
        }
        return pairs;
    }
    detect_collision(p_i, p_j, margin) {
        const pos_i = p_i.pos;
        const pos_j = p_j.pos;
        const extents_x = p_i.half_extents[0] + p_j.half_extents[0] + margin;
        const extents_y = p_i.half_extents[1] + p_j.half_extents[1] + margin;
        const extents_z = p_i.half_extents[2] + p_j.half_extents[2] + margin;
        const overlaps = [
            pos_j[0] - pos_i[0] + extents_x,
            pos_i[0] - pos_j[0] + extents_x,
            pos_j[1] - pos_i[1] + extents_y,
            pos_i[1] - pos_j[1] + extents_y,
            pos_j[2] - pos_i[2] + extents_z,
            pos_i[2] - pos_j[2] + extents_z,
        ];
        let best_overlap = overlaps[0];
        let best_overlap_i = 0;
        for (let i = 0; i < overlaps.length; i++) {
            const overlap = overlaps[i];
            if (overlap < 0.0) {
                return null;
            }
            if (overlap < best_overlap) {
                best_overlap = overlap;
                best_overlap_i = i;
            }
        }
        // The direction j will be pushed.
        const dir = aabb_vecs[best_overlap_i];
        const c = new Collision();
        c.best_overlap = best_overlap;
        c.dir = dir;
        return c;
    }
    physics_substep(ctx, collision_pairs) {
        const h = ctx.h;
        const physics = this.ecs.physics;
        // println(`Considering ${collision_pairs.length} / ${physics.size * (physics.size - 1) / 2} pairs`);
        // Integrate gravity, etc.
        for (const [ent, p] of [...physics]) {
            ctx.g_forces.set(ent, 0.0);
            p.prev_pos.set_to(p.pos);
            if (p.inv_mass > 0.0) {
                p.vel.fmadd_scalar_mut(ctx.gravity, h);
            }
            p.pos.fmadd_scalar_mut(p.vel, h);
        }
        // Enforce gts grab constraint
        for (const [ent, t] of [...this.ecs.grabables]) {
            const p = physics.get(ent);
            if (t.grabbed) {
                p.pos = this.gts.pos.add(new Vec3([1.0, 2.0, 0.0]));
                p.vel = Vec3.create();
            }
            else {
                // Boop-boop-be-doop
            }
        }
        // Push AABBs aparts
        for (const [i, j] of collision_pairs) {
            this.handle_collision_pair(ctx, i, j);
        }
        // Update velocity
        for (const [ent, p] of [...physics]) {
            p.vel.set_fmadd_scalar(p.pos, p.prev_pos, -1.0);
            p.vel.scale_mut(1.0 / h);
        }
        // Note objects with high g-forces
        // Murderize em
        for (const [ent, ent_p] of [...physics]) {
            const r = this.ecs.renderables.get(ent);
            if (r != undefined) {
                const g = ctx.g_forces.get(ent);
                if (g > 0.02) {
                    // println(`${ent} ("${r.name}"): ${g} g`);
                    if (g >= ent_p.g_force_kill) {
                        // println(`${ent} ("${r.name}") died of ${g} gs`);
                        this.ecs.kersplode(ent, Vec3.create());
                    }
                }
            }
        }
    }
    handle_collision_pair(ctx, i, j) {
        const h = ctx.h;
        const physics = this.ecs.physics;
        if (!physics.has(i) || !physics.has(j)) {
            // Object was killed between substeps
            return;
        }
        const p_i = physics.get(i);
        const p_j = physics.get(j);
        if (p_i.is_particle && p_j.is_particle) {
            return;
        }
        if (p_i.inv_mass + p_j.inv_mass == 0.0) {
            return;
        }
        const c = this.detect_collision(p_i, p_j, 0.0);
        if (c == null) {
            return;
        }
        const best_overlap = c.best_overlap;
        const dir = c.dir;
        const sum_inv_mass = p_i.inv_mass + p_j.inv_mass;
        const lambda = best_overlap / sum_inv_mass;
        p_j.pos.fmadd_scalar_mut(dir, p_j.inv_mass * lambda);
        p_i.pos.fmadd_scalar_mut(dir, -p_i.inv_mass * lambda);
        // Correct the velocity for a fully inelastic collision
        const vel_rel = p_i.vel.sub(p_j.vel);
        const normal_speed = vel_rel.dot(dir);
        if (normal_speed > 0.0) {
            const delta_v_i = -p_i.inv_mass * normal_speed / sum_inv_mass;
            const delta_v_j = p_j.inv_mass * normal_speed / sum_inv_mass;
            p_i.vel.fmadd_scalar_mut(dir, delta_v_i);
            p_j.vel.fmadd_scalar_mut(dir, delta_v_j);
            ctx.g_forces.set(i, ctx.g_forces.get(i) + Math.abs(delta_v_i));
            ctx.g_forces.set(j, ctx.g_forces.get(j) + Math.abs(delta_v_j));
        }
        // Apply some half-assed friction
        const tangent_v = Vec3.create();
        tangent_v.set_fmadd_scalar(vel_rel, dir, -normal_speed);
        const mu = p_i.mu * p_j.mu * lambda / h;
        const tangent_speed_sq = tangent_v.length_squared();
        if (tangent_speed_sq > 0.0) {
            const tangent_speed = Math.sqrt(tangent_speed_sq);
            const friction_scale = -(Math.min(mu, tangent_speed)) / tangent_speed / sum_inv_mass;
            p_j.vel.fmadd_scalar_mut(tangent_v, friction_scale * -p_j.inv_mass);
            p_i.vel.fmadd_scalar_mut(tangent_v, friction_scale * p_i.inv_mass);
        }
        // Back-calculate prev_pos to avoid explosions
        p_i.prev_pos.set_fmadd_scalar(p_i.pos, p_i.vel, -h);
        p_j.prev_pos.set_fmadd_scalar(p_j.pos, p_j.vel, -h);
    }
    do_action_1() {
        const gts = this.gts;
        // Try to drop whatever we're holding
        if (gts.grabbed_ent != null) {
            const ent = gts.grabbed_ent;
            gts.grabbed_ent = null;
            const t = this.ecs.grabables.get(ent);
            t.grabbed = false;
            println(`Dropped ${ent}`);
            return;
        }
        // Try to grab something in front of us
        const grab_pos = gts.pos.add(new Vec3([1.0, 0.0, 0.0]));
        let best_ent_to_grab = null;
        let best_grab_score = 0.0;
        for (const ent of this.ecs.grabables.keys()) {
            if (!this.ecs.physics.has(ent)) {
                println("All grabables should have physics");
            }
            const p = this.ecs.physics.get(ent);
            // Grab hitbox is a cylinder
            const diff = p.pos.sub(grab_pos);
            if (diff.y() > 2.0) {
                // Too high
                continue;
            }
            else if (diff.y() < -0.1) {
                // Too low
                continue;
            }
            else if (diff.mul(new Vec3([1.0, 0.0, 1.0])).length_squared() > 1.0) {
                // Outside cylinder
                continue;
            }
            // Overall, prefer heavier objects like trucks
            // Then tie-break with height, and finally proximity
            const grab_score = 10000 / p.inv_mass + diff.y() * 10.0 - diff.x();
            if (grab_score > best_grab_score) {
                best_ent_to_grab = ent;
            }
        }
        if (best_ent_to_grab != null) {
            const t = this.ecs.grabables.get(best_ent_to_grab);
            gts.grabbed_ent = best_ent_to_grab;
            t.grabbed = true;
            // println(`Grabbed ${best_ent_to_grab}`);
        }
        println("Can't find any action to do");
    }
    do_action_2() {
        const gts = this.gts;
        const ent = gts.grabbed_ent;
        if (ent == null) {
            println("Nothing to throw / swallow");
        }
        // Swallow
        if (this.ecs.edibles.has(ent) && gts.digesting == null) {
            const r = this.ecs.renderables.get(ent);
            this.ecs.del_ent(ent);
            gts.grabbed_ent = null;
            const d = new Digesting();
            d.renderable = r;
            gts.digesting = d;
            println(`Swallowed ${r.gltf}`);
            return;
        }
        // Throw
        if (this.ecs.grabables.has(ent)) {
            const p = this.ecs.physics.get(ent);
            p.vel = new Vec3([12.0, -4.0, 0.0]);
            const t = this.ecs.grabables.get(ent);
            t.grabbed = false;
            gts.grabbed_ent = null;
            return;
        }
        println("Cannot throw / swallow this item");
        return;
    }
}
export class GameState {
    constructor(resources) {
        this.frame_count = 0;
        this.camera_pos = [0.0, -4.0, 1.5];
        this.camera_pitch = -90.0;
        this.state = state_menu;
        this.playing = new PlayingState();
        this.sfx_triggers = new SoundEffectTriggers();
        this.menu = new MenuState(resources);
    }
    step(gamepad, running) {
        this.frame_count += 1;
        if (this.state == state_racing) {
            this.playing.step(gamepad, this.sfx_triggers);
        }
        else if (this.state == state_menu) {
            this.menu.step(this, gamepad, this.sfx_triggers);
        }
    }
}
