// Copied from lightgl.js
export class Vec3 extends Float32Array {
    static create() {
        const dest = new Vec3(3);
        dest[0] = 0.0;
        dest[1] = 0.0;
        dest[2] = 0.0;
        return dest;
    }
    static zero() {
        return new Vec3([0, 0, 0]);
    }
    x() { return this[0]; }
    y() { return this[1]; }
    z() { return this[2]; }
    add(b) {
        const a = this;
        return new Vec3([a[0] + b[0], a[1] + b[1], a[2] + b[2]]);
    }
    // It's 8 C.E. (Crab Era) and allocating a Float32Array is still slow
    // Q_Q
    add_mut(b) {
        this[0] += b[0];
        this[1] += b[1];
        this[2] += b[2];
    }
    add3_mut(x, y, z) {
        this[0] += x;
        this[1] += y;
        this[2] += z;
    }
    clone() {
        const v = Vec3.create();
        for (let i = 0; i < 3; i++) {
            v[i] = this[i];
        }
        return v;
    }
    dot(b) {
        const a = this;
        return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
    }
    fmadd_scalar_mut(b, s) {
        for (let i = 0; i < 3; i++) {
            this[i] = this[i] + (b[i] * s);
        }
    }
    inverted() {
        return this.scaled(-1.0);
    }
    vec_length() {
        return Math.sqrt(this.length_squared());
    }
    length_squared() {
        return this.dot(this);
    }
    mul(b) {
        const a = this;
        return new Vec3([a[0] * b[0], a[1] * b[1], a[2] * b[2]]);
    }
    normalized() {
        const len = Math.sqrt(this.length_squared());
        if (len == 0.0) {
            return new Vec3([0.0, 0.0, 0.0]);
        }
        return this.scaled(1.0 / len);
    }
    scale_mut(s) {
        for (let i = 0; i < 3; i++) {
            this[i] *= s;
        }
    }
    scaled(s) {
        return new Vec3([s * this[0], s * this[1], s * this[2]]);
    }
    set_to(v) {
        for (let i = 0; i < 3; i++) {
            this[i] = v[i];
        }
    }
    set_fmadd_scalar(a, b, s) {
        for (let i = 0; i < 3; i++) {
            this[i] = a[i] + (b[i] * s);
        }
    }
    sub(b) {
        const a = this;
        return new Vec3([a[0] - b[0], a[1] - b[1], a[2] - b[2]]);
    }
}
export class Mat4 extends Float32Array {
    static create() {
        const dest = new Mat4(16);
        dest[0] = 1.0;
        dest[5] = 1.0;
        dest[10] = 1.0;
        dest[15] = 1.0;
        return dest;
    }
    static frustum(m, l, r, b, t, n, f) {
        m[0] = 2 * n / (r - l);
        m[4] = 0;
        m[8] = 0.0;
        m[12] = 0;
        m[1] = 0;
        m[5] = 2 * n / (t - b);
        m[9] = 0.0;
        m[13] = 0;
        m[2] = 0;
        m[6] = 0;
        m[10] = 0.0;
        m[14] = -2 * f * n / (f - n);
        m[3] = 0;
        m[7] = 0;
        m[11] = -1;
        m[15] = 0;
    }
    static ortho(n, f, aspect) {
        const m = Mat4.create();
        // Z --> X
        m[8] = 0.0;
        // Z --> Z
        m[10] = -1.0 / 1.0;
        m[11] = 0;
        // Z offset
        m[14] = -1.0;
        return m;
    }
    transposed() {
        const t = this;
        return new Mat4([
            t[0], t[4], t[8], t[12],
            t[1], t[5], t[9], t[13],
            t[2], t[6], t[10], t[14],
            t[3], t[7], t[11], t[15],
        ]);
    }
    // Copied from mat4.js. I'm sure their code is faster but
    // I really wanted the FP style
    inverted() {
        const a = this;
        const out = Mat4.create();
        let a00 = a[0], a01 = a[1], a02 = a[2], a03 = a[3];
        let a10 = a[4], a11 = a[5], a12 = a[6], a13 = a[7];
        let a20 = a[8], a21 = a[9], a22 = a[10], a23 = a[11];
        let a30 = a[12], a31 = a[13], a32 = a[14], a33 = a[15];
        let b00 = a00 * a11 - a01 * a10;
        let b01 = a00 * a12 - a02 * a10;
        let b02 = a00 * a13 - a03 * a10;
        let b03 = a01 * a12 - a02 * a11;
        let b04 = a01 * a13 - a03 * a11;
        let b05 = a02 * a13 - a03 * a12;
        let b06 = a20 * a31 - a21 * a30;
        let b07 = a20 * a32 - a22 * a30;
        let b08 = a20 * a33 - a23 * a30;
        let b09 = a21 * a32 - a22 * a31;
        let b10 = a21 * a33 - a23 * a31;
        let b11 = a22 * a33 - a23 * a32;
        // Calculate the determinant
        let det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
        if (det == 0.0) {
            throw new Error("Could not invert matrix");
        }
        det = 1.0 / det;
        out[0] = (a11 * b11 - a12 * b10 + a13 * b09) * det;
        out[1] = (a02 * b10 - a01 * b11 - a03 * b09) * det;
        out[2] = (a31 * b05 - a32 * b04 + a33 * b03) * det;
        out[3] = (a22 * b04 - a21 * b05 - a23 * b03) * det;
        out[4] = (a12 * b08 - a10 * b11 - a13 * b07) * det;
        out[5] = (a00 * b11 - a02 * b08 + a03 * b07) * det;
        out[6] = (a32 * b02 - a30 * b05 - a33 * b01) * det;
        out[7] = (a20 * b05 - a22 * b02 + a23 * b01) * det;
        out[8] = (a10 * b10 - a11 * b08 + a13 * b06) * det;
        out[9] = (a01 * b08 - a00 * b10 - a03 * b06) * det;
        out[10] = (a30 * b04 - a31 * b02 + a33 * b00) * det;
        out[11] = (a21 * b02 - a20 * b04 - a23 * b00) * det;
        out[12] = (a11 * b07 - a10 * b09 - a12 * b06) * det;
        out[13] = (a00 * b09 - a01 * b07 + a02 * b06) * det;
        out[14] = (a31 * b01 - a30 * b03 - a32 * b00) * det;
        out[15] = (a20 * b03 - a21 * b01 + a22 * b00) * det;
        return out;
    }
    static multiply(r, a, b) {
        r[0] = a[0] * b[0] + a[1] * b[4] + a[2] * b[8] + a[3] * b[12];
        r[1] = a[0] * b[1] + a[1] * b[5] + a[2] * b[9] + a[3] * b[13];
        r[2] = a[0] * b[2] + a[1] * b[6] + a[2] * b[10] + a[3] * b[14];
        r[3] = a[0] * b[3] + a[1] * b[7] + a[2] * b[11] + a[3] * b[15];
        r[4] = a[4] * b[0] + a[5] * b[4] + a[6] * b[8] + a[7] * b[12];
        r[5] = a[4] * b[1] + a[5] * b[5] + a[6] * b[9] + a[7] * b[13];
        r[6] = a[4] * b[2] + a[5] * b[6] + a[6] * b[10] + a[7] * b[14];
        r[7] = a[4] * b[3] + a[5] * b[7] + a[6] * b[11] + a[7] * b[15];
        r[8] = a[8] * b[0] + a[9] * b[4] + a[10] * b[8] + a[11] * b[12];
        r[9] = a[8] * b[1] + a[9] * b[5] + a[10] * b[9] + a[11] * b[13];
        r[10] = a[8] * b[2] + a[9] * b[6] + a[10] * b[10] + a[11] * b[14];
        r[11] = a[8] * b[3] + a[9] * b[7] + a[10] * b[11] + a[11] * b[15];
        r[12] = a[12] * b[0] + a[13] * b[4] + a[14] * b[8] + a[15] * b[12];
        r[13] = a[12] * b[1] + a[13] * b[5] + a[14] * b[9] + a[15] * b[13];
        r[14] = a[12] * b[2] + a[13] * b[6] + a[14] * b[10] + a[15] * b[14];
        r[15] = a[12] * b[3] + a[13] * b[7] + a[14] * b[11] + a[15] * b[15];
    }
    multiply(m) {
        const result = Mat4.create();
        Mat4.multiply(result, this, m);
        return result;
    }
    multiply_vec4(v) {
        const m = this;
        return [
            m[0] * v[0] + m[4] * v[1] + m[8] * v[2] + m[12] * v[3],
            m[1] * v[0] + m[5] * v[1] + m[9] * v[2] + m[13] * v[3],
            m[2] * v[0] + m[6] * v[1] + m[10] * v[2] + m[14] * v[3],
            m[3] * v[0] + m[7] * v[1] + m[11] * v[2] + m[15] * v[3],
        ];
    }
    multiply_vec3(v, w) {
        const result = this.multiply_vec4([v[0], v[1], v[2], w]);
        return new Vec3([
            result[0],
            result[1],
            result[2],
        ]);
    }
    static perspective(result, fov, aspect, z_near, z_far) {
        const y = Math.tan(fov * 0.5) * z_near;
        const x = y * aspect;
        Mat4.frustum(result, -x, x, -y, y, z_near, z_far);
    }
    static rotate(m, a, x, y, z) {
        const c = Math.cos(a);
        const s = Math.sin(a);
        const t = 1 - c;
        m[0] = x * x * t + c;
        m[4] = x * y * t - z * s;
        m[8] = x * z * t + y * s;
        m[12] = 0;
        m[1] = y * x * t + z * s;
        m[5] = y * y * t + c;
        m[9] = y * z * t - x * s;
        m[13] = 0;
        m[2] = z * x * t - y * s;
        m[6] = z * y * t + x * s;
        m[10] = z * z * t + c;
        m[14] = 0;
        m[3] = 0;
        m[7] = 0;
        m[11] = 0;
        m[15] = 1;
    }
    rotate(a, x, y, z) {
        const m = Mat4.create();
        Mat4.rotate(m, a, x, y, z);
        return this.multiply(m);
    }
    static scale(dest, v) {
        // whatever
        dest[0] *= v[0];
        dest[5] *= v[1];
        dest[10] *= v[2];
    }
    scale(v) {
        const m = Mat4.create();
        Mat4.scale(m, v);
        return this.multiply(m);
    }
    scale_1(s) {
        return this.scale([s, s, s]);
    }
    static translate(dest, src, v) {
        for (let i = 0; i < 16; i++) {
            dest[i] = src[i];
        }
        dest[12] += v[0];
        dest[13] += v[1];
        dest[14] += v[2];
    }
    translate(v) {
        const m = Mat4.create();
        Mat4.translate(m, m, v);
        return this.multiply(m);
    }
}
