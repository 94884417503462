import { Vec3 } from "./matrix";
export function point_in_sphere() {
    let theta = Math.random() * 2.0 * Math.PI;
    let phi = (1.0 - Math.sqrt(Math.random())) * Math.PI * 0.5;
    if (Math.random() > 0.5) {
        phi = -phi;
    }
    let r = Math.sqrt(Math.random());
    return new Vec3([
        r * Math.cos(phi) * Math.cos(theta),
        r * Math.cos(phi) * Math.sin(theta),
        r * Math.sin(phi),
    ]);
}
