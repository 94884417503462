export var ATTR;
(function (ATTR) {
    ATTR.POS = "attr_pos";
    ATTR.NORM = "attr_normal";
    ATTR.UV = "attr_tex_coord";
    ATTR.JOINTS = "attr_joints";
    ATTR.WEIGHTS = "attr_weights";
})(ATTR || (ATTR = {}));
export var GLTF;
(function (GLTF) {
    GLTF.ANIM_TEST = "wacky_waving_inflatable_arm_flailing_animation_test_person";
    GLTF.CIVILIAN = "civilian";
    GLTF.CLIFFSIDE = "cliffside";
    GLTF.POINTY_BUILDING = "pointy_building";
    GLTF.POMEGRANATE = "pomegranate";
    GLTF.SKY = "sky";
    GLTF.SLIME = "slime-body";
    GLTF.all = [
        GLTF.ANIM_TEST,
        GLTF.CLIFFSIDE,
        GLTF.POINTY_BUILDING,
        GLTF.POMEGRANATE,
        GLTF.SKY,
        GLTF.SLIME,
    ];
})(GLTF || (GLTF = {}));
export var SCENE;
(function (SCENE) {
    SCENE.CLIFFSIDE = "city_scene";
    SCENE.all = [
        SCENE.CLIFFSIDE,
    ];
})(SCENE || (SCENE = {}));
export var SHADER;
(function (SHADER) {
    SHADER.ANIMATION = "animation";
    SHADER.BILLBOARD = "billboard";
    SHADER.DEFAULT = "default";
    SHADER.SHADOW = "shadow";
    SHADER.SLIME = "slime";
    SHADER.SLIME_FRONT = "slime_front";
    SHADER.TEXT = "text";
    SHADER.all = [
        SHADER.BILLBOARD,
        SHADER.DEFAULT,
        SHADER.SHADOW,
        SHADER.SLIME,
        SHADER.SLIME_FRONT,
        SHADER.TEXT,
    ];
})(SHADER || (SHADER = {}));
export var TEXTURE;
(function (TEXTURE) {
    TEXTURE.BLOOD = "textures/blood.png";
    TEXTURE.BUBBLE = "textures/bubble.png";
    TEXTURE.CIVILIAN = "textures/civilian.png";
    TEXTURE.CLIFFSIDE = "textures/cliffside.png";
    TEXTURE.EXPLOSION = "textures/explosion.png";
    TEXTURE.FONT = "textures/font.png";
    TEXTURE.POMEGRANATE = "textures/pomegranate.png";
    TEXTURE.SKY = "textures/sky.jpeg";
    TEXTURE.WHITE = "_white";
    TEXTURE.all = [
        TEXTURE.BLOOD,
        TEXTURE.BUBBLE,
        TEXTURE.CLIFFSIDE,
        TEXTURE.EXPLOSION,
        TEXTURE.FONT,
        TEXTURE.POMEGRANATE,
        TEXTURE.SKY,
    ];
})(TEXTURE || (TEXTURE = {}));
export var UNI;
(function (UNI) {
    UNI.JOINT_MAT = "joint_mat";
    UNI.PROJ_MAT = "uProjectionMatrix";
    UNI.MODEL_MAT = "uni_model_mat";
    UNI.MV_MAT = "uModelViewMatrix";
    UNI.SHADOW_MAT = "uni_shadow_mat";
    UNI.OBJ_SPACE_SKY = "uni_obj_space_sky";
    UNI.OBJ_SPACE_SUN = "uni_obj_space_sun";
    UNI.OBJ_SPACE_EYE = "uni_obj_space_eye";
    UNI.RADIUS = "uni_radius";
    UNI.SAMPLER = "uni_sampler";
    UNI.SHADOW_MAP = "uni_shadow_map";
    UNI.MAX_BRIGHT = "uni_max_bright";
    UNI.FULLBRIGHT = "uni_fullbright";
    UNI.USE_UV_COORDS = "uni_use_uv_coords";
    UNI.USE_REFRACTION_COORDS = "uni_use_refraction_coords";
})(UNI || (UNI = {}));
