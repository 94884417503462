import { println } from "./print";
function load_shader(gl, name, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        const log = gl.getShaderInfoLog(shader);
        println(`An error occurred compiling the shader ${name}:${type}: ${log}`);
        gl.deleteShader(shader);
        return null;
    }
    return shader;
}
function init_shader_program(gl, name, vert_shader_source, frag_shader_source) {
    const vertexShader = load_shader(gl, name, gl.VERTEX_SHADER, vert_shader_source);
    const fragmentShader = load_shader(gl, name, gl.FRAGMENT_SHADER, frag_shader_source);
    // Create the shader program
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);
    // If creating the shader program failed, alert
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        throw new Error(`Unable to initialize the shader program ${name} : ${gl.getProgramInfoLog(shaderProgram)}`);
        return null;
    }
    return shaderProgram;
}
export class ProgramInfo {
    constructor(gl, name, sources, attribs, uniforms) {
        this.program = init_shader_program(gl, name, sources[0], sources[1]);
        this.attrib_locations = new Map(attribs.map(key => [key, gl.getAttribLocation(this.program, key)]));
        this.uniform_locations = new Map(uniforms.map(key => [key, gl.getUniformLocation(this.program, key)]));
    }
}
