// Stuff for loading `.tscn` files that Godot puts out.
import { Mat4, Vec3 } from "./matrix";
import { println } from "./print";
export class Scene {
}
export class Node {
}
export class ExtResource {
}
export function load(s) {
    const lines = s.split('\n');
    // Thank you ChatGPT!
    const nodes = [];
    let current_node = null;
    const ext_resources = new Map();
    for (const line of lines) {
        if (line.startsWith("[node")) {
            if (current_node != null) {
                nodes.push(current_node);
            }
            const matches = line.match(/node name="([^"]*)" parent="([^"]*)" instance=ExtResource\("([^"]*)"\)/);
            if (matches) {
                current_node = new Node();
                const [_, name, parent, instance] = matches;
                current_node.name = name;
                current_node.parent = parent;
                current_node.instance = instance;
            }
        }
        else if (line.startsWith("[ext_resource")) {
            const matches = line.match(/ext_resource type="PackedScene" uid="([^"]*)" path="res:\/\/blends\/([^"]*).gltf" id="([^"]*)"/);
            if (matches) {
                const res = new ExtResource();
                const [_, uid, gltf_name, id] = matches;
                res.uid = uid;
                res.gltf_name = gltf_name;
                res.id = id;
                ext_resources.set(res.id, res);
                println(`Registered ExtResource ${res.id}, ${res.gltf_name}`);
            }
        }
        else if (line.startsWith("transform")) {
            const matches = line.match(/transform = Transform3D\(([^)]*)\)/);
            if (matches) {
                {
                    const [_, transformValues] = matches;
                    const t = transformValues.split(',').map(Number);
                    current_node.transform = new Mat4([
                        t[0], t[3], t[6], 0.0,
                        t[1], t[4], t[7], 0.0,
                        t[2], t[5], t[8], 0.0,
                        t[9], t[10], t[11], 1.0,
                    ]);
                }
                {
                    const t = current_node.transform.inverted();
                    const scale = new Vec3([t[0], t[1], t[2]]).vec_length();
                    for (let i = 0; i < 4 * 3; i++) {
                        t[i] = t[i] / scale;
                    }
                    current_node.light_transform = t;
                }
            }
        }
    }
    if (current_node != null) {
        nodes.push(current_node);
    }
    const scene = new Scene();
    scene.nodes = nodes;
    scene.ext_resources = ext_resources;
    return scene;
}
