// Responsible for fetching bytes and images asynchronously, but doesn't
// parse anything or load anything into WebGL
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GLTF, SCENE, SHADER, TEXTURE } from "./consts";
import { println } from "./print";
import * as Tscn from "./tscn";
export class Resources {
}
export function load_resources() {
    return __awaiter(this, void 0, void 0, function* () {
        let resources = new Resources();
        const gltfs_needed = new Map();
        const textures_needed = new Map();
        for (const key of TEXTURE.all) {
            textures_needed.set(key, true);
        }
        for (const key of GLTF.all) {
            gltfs_needed.set(key, true);
        }
        {
            const promises = SCENE.all.map(k => fetch_tscn(k));
            resources.scenes = new Map(yield Promise.all(promises));
            for (const [_, scene] of [...resources.scenes]) {
                for (const [id, res] of [...scene.ext_resources]) {
                    gltfs_needed.set(res.gltf_name, true);
                    textures_needed.set(`textures/${res.gltf_name}.png`, true);
                }
            }
        }
        yield Promise.all([
            (() => __awaiter(this, void 0, void 0, function* () {
                const promises = Array.from(gltfs_needed.keys()).map(k => fetch_gltf(k));
                try {
                    let results = yield Promise.all(promises);
                    resources.gltfs = new Map(results);
                }
                catch (err) {
                    println(`Error while loading gltfs: ${err.message}`);
                }
            }))(),
            (() => __awaiter(this, void 0, void 0, function* () {
                const promises = SHADER.all.map(k => fetch_shader(k));
                resources.shaders = new Map(yield Promise.all(promises));
            }))(),
            (() => __awaiter(this, void 0, void 0, function* () {
                let promises = Promise.all(Array.from(textures_needed.keys()).map(k => fetch_img(k)));
                resources.textures = new Map(yield promises);
            }))(),
        ]);
        return resources;
    });
}
function fetch_tscn(key) {
    return __awaiter(this, void 0, void 0, function* () {
        const path = `godot/${key}.tscn`;
        const [_, data] = yield fetch_text(path);
        const scene = Tscn.load(data);
        return [key, scene];
    });
}
function fetch_gltf(key) {
    return __awaiter(this, void 0, void 0, function* () {
        const gltf_url = `godot/blends/${key}.gltf`;
        const bin_url = `godot/blends/${key}.bin`;
        let gltf;
        let bin;
        yield Promise.all([
            (() => __awaiter(this, void 0, void 0, function* () {
                try {
                    let response = yield fetch(gltf_url);
                    if (!response.ok) {
                        throw new Error(`fetch(${gltf_url}) not ok`);
                    }
                    gltf = JSON.parse(yield response.text());
                }
                catch (err) {
                    throw new Error(`fetch failed: ${gltf_url}`);
                }
            }))(),
            (() => __awaiter(this, void 0, void 0, function* () {
                try {
                    let response = yield fetch(bin_url);
                    if (!response.ok) {
                        throw new Error(`fetch(${bin_url}) not ok`);
                    }
                    bin = yield response.arrayBuffer();
                }
                catch (err) {
                    println("This should totes throw");
                    throw new Error(`fetch failed: ${bin_url}`);
                }
            }))(),
        ]);
        return [key, [gltf, bin]];
    });
}
function fetch_img(key) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve([key, img]);
            img.onerror = (error) => {
                println(`Failed to load img ${key}: ${error}`);
                resolve([key, null]);
            };
            img.src = `godot/blends/${key}`;
        }).then(([key, img]) => __awaiter(this, void 0, void 0, function* () {
            if (img == null) {
                return [key, null];
            }
            const img_bmp = yield createImageBitmap(img);
            return [key, img_bmp];
        }));
    });
}
function fetch_shader(url_base) {
    return __awaiter(this, void 0, void 0, function* () {
        let promises = [
            fetch_text(`shaders/${url_base}_vert.glsl`),
            fetch_text(`shaders/${url_base}_frag.glsl`)
        ];
        let texts = yield Promise.all(promises);
        return [url_base, [texts[0][1], texts[1][1]]];
    });
}
function fetch_text(url) {
    return __awaiter(this, void 0, void 0, function* () {
        let response = yield fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not OK");
        }
        return [url, yield response.text()];
    });
}
