var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Billboards } from "./billboards";
import { ATTR, GLTF, SCENE, SHADER, TEXTURE, UNI } from "./consts";
import * as Gltf from "./gltf";
import { Mat4, Vec3 } from "./matrix";
import { println } from "./print";
import * as RandomThings from "./random_things";
import { ProgramInfo } from "./shader";
const SHADOW_MAP_RES = 1024;
function is_pot(value) {
    return (value & (value - 1)) == 0;
}
function load_color_texture(gl, pixel) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    const level = 0;
    const internalFormat = gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, new Uint8Array(pixel));
    return texture;
}
function texture_from_img(gl, image) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    const level = 0;
    const internalFormat = gl.RGBA;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
    // WebGL1 has different requirements for power of 2 images
    // vs non power of 2 images so check if the image is a
    // power of 2 in both dimensions.
    if (is_pot(image.width) && is_pot(image.height)) {
        // Yes, it's a power of 2. Generate mips.
        gl.generateMipmap(gl.TEXTURE_2D);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    }
    else {
        // No, it's not a power of 2. Turn off mips and set
        // wrapping to clamp to edge
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    }
    return texture;
}
function create_proj_mat(gl, zNear, zFar, canvas_width, canvas_height) {
    const fieldOfView = 45 * Math.PI / 180; // in radians
    const aspect = canvas_width / canvas_height;
    const projection_matrix = Mat4.create();
    // note: glmatrix.js always has the first argument
    // as the destination to receive the result.
    Mat4.perspective(projection_matrix, fieldOfView, aspect, zNear, zFar);
    return projection_matrix;
}
function use_program(gl, s) {
    gl.useProgram(s.program);
    for (const key of [ATTR.POS, ATTR.NORM, ATTR.UV, ATTR.WEIGHTS, ATTR.JOINTS]) {
        const loc = s.attrib_locations.get(key);
        if (loc != -1) {
            gl.enableVertexAttribArray(loc);
        }
    }
}
const id_mat = Mat4.create();
class FrameContext {
    constructor() {
        this.sun_dir = Vec3.create();
        this.proj_mat = Mat4.create();
        this.view_mat = Mat4.create();
        this.view_sky_mat = Mat4.create();
    }
}
export class Graphics {
    constructor(gl, resources) {
        this.gl = gl;
        this.gltfs = new Map([...resources.gltfs].map(([k, v]) => {
            let renderable = null;
            try {
                const [model, bytes] = resources.gltfs.get(k);
                renderable = new Gltf.Renderable(gl, model, bytes);
            }
            catch (error) {
                throw `glTF ${k}: ${error.message}`;
            }
            return [k, renderable];
        }));
        this.scene = resources.scenes.get(SCENE.CLIFFSIDE);
        this.shaders = new Map();
        this.shaders.set(SHADER.BILLBOARD, new ProgramInfo(gl, SHADER.BILLBOARD, resources.shaders.get(SHADER.BILLBOARD), [
            ATTR.POS,
            ATTR.UV,
        ], [
            UNI.PROJ_MAT,
            UNI.MV_MAT,
            UNI.RADIUS,
            UNI.SAMPLER,
        ]));
        this.shaders.set(SHADER.DEFAULT, new ProgramInfo(gl, SHADER.DEFAULT, resources.shaders.get(SHADER.DEFAULT), [
            ATTR.POS,
            ATTR.NORM,
            ATTR.UV,
            ATTR.JOINTS,
            ATTR.WEIGHTS,
        ], [
            UNI.JOINT_MAT,
            UNI.PROJ_MAT,
            UNI.MODEL_MAT,
            UNI.MV_MAT,
            UNI.OBJ_SPACE_SKY,
            UNI.OBJ_SPACE_SUN,
            UNI.OBJ_SPACE_EYE,
            UNI.SAMPLER,
            UNI.SHADOW_MAP,
            UNI.SHADOW_MAT,
            UNI.MAX_BRIGHT,
            UNI.FULLBRIGHT,
        ]));
        this.shaders.set(SHADER.SHADOW, new ProgramInfo(gl, SHADER.SHADOW, resources.shaders.get(SHADER.SHADOW), [
            ATTR.POS,
            ATTR.NORM,
            ATTR.UV,
            ATTR.JOINTS,
            ATTR.WEIGHTS,
        ], [
            UNI.JOINT_MAT,
            UNI.PROJ_MAT,
            UNI.MODEL_MAT,
            UNI.MV_MAT,
            UNI.OBJ_SPACE_SKY,
            UNI.OBJ_SPACE_SUN,
            UNI.OBJ_SPACE_EYE,
            UNI.SAMPLER,
            UNI.SHADOW_MAP,
            UNI.MAX_BRIGHT,
            UNI.FULLBRIGHT,
        ]));
        this.shaders.set(SHADER.SLIME, new ProgramInfo(gl, SHADER.SLIME, resources.shaders.get(SHADER.SLIME), [
            ATTR.POS,
            ATTR.NORM,
            ATTR.UV,
            ATTR.JOINTS,
            ATTR.WEIGHTS,
        ], [
            UNI.JOINT_MAT,
            UNI.PROJ_MAT,
            UNI.MV_MAT,
            UNI.OBJ_SPACE_SKY,
            UNI.OBJ_SPACE_SUN,
            UNI.OBJ_SPACE_EYE,
            UNI.SAMPLER,
            UNI.MAX_BRIGHT,
            UNI.FULLBRIGHT,
            UNI.USE_UV_COORDS,
            UNI.USE_REFRACTION_COORDS,
        ]));
        this.shaders.set(SHADER.SLIME_FRONT, new ProgramInfo(gl, SHADER.SLIME_FRONT, resources.shaders.get(SHADER.SLIME_FRONT), [
            ATTR.POS,
            ATTR.NORM,
            ATTR.UV,
            ATTR.JOINTS,
            ATTR.WEIGHTS,
        ], [
            UNI.JOINT_MAT,
            UNI.PROJ_MAT,
            UNI.MV_MAT,
            UNI.OBJ_SPACE_SKY,
            UNI.OBJ_SPACE_SUN,
            UNI.OBJ_SPACE_EYE,
            UNI.SAMPLER,
            UNI.MAX_BRIGHT,
            UNI.FULLBRIGHT,
            UNI.USE_UV_COORDS,
            UNI.USE_REFRACTION_COORDS,
        ]));
        this.shaders.set(SHADER.TEXT, new ProgramInfo(gl, SHADER.TEXT, resources.shaders.get(SHADER.TEXT), [
            ATTR.POS,
            ATTR.UV,
        ], [
            UNI.PROJ_MAT,
            UNI.MV_MAT,
            UNI.RADIUS,
            UNI.SAMPLER,
        ]));
        this.textures = new Map();
        this.textures.set(TEXTURE.WHITE, load_color_texture(gl, [255, 255, 255, 255]));
        this.billboards = new Billboards(gl, 1024);
        this.bubbles = new Array();
        for (let i = 0; i < 16; i++) {
            this.bubbles[i] = RandomThings.point_in_sphere().scaled(0.3);
        }
        this.framebuffer_refract = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer_refract);
        this.texture_refract = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.texture_refract);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 512, 512, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.texture_refract, 0);
        this.texture_refract_depth = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.texture_refract_depth);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT24, 512, 512, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.texture_refract_depth, 0);
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) != gl.FRAMEBUFFER_COMPLETE) {
            println("Framebuffer is not complete");
        }
        this.fbo_shadow = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.fbo_shadow);
        this.tex_shadow_depth = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.tex_shadow_depth);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT24, SHADOW_MAP_RES, SHADOW_MAP_RES, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.tex_shadow_depth, 0);
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) != gl.FRAMEBUFFER_COMPLETE) {
            println("Framebuffer is not complete");
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }
    load_textures(gl, resources) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const [k, v] of [...resources.textures]) {
                if (v == null) {
                    this.textures.set(k, load_color_texture(gl, [255, 0, 255, 255]));
                }
                else {
                    this.textures.set(k, yield texture_from_img(gl, v));
                }
            }
            gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.FONT));
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        });
    }
    draw_scene(game_state, ctx_2d, canvas_width, canvas_height) {
        const gl = this.gl;
        const draw_billboards = true;
        const draw_slime = true;
        const gts = game_state.menu.gts;
        gl.clearColor(1.0, 0.0, 1.0, 1.0);
        gl.enable(gl.CULL_FACE);
        gl.disable(gl.BLEND);
        const frame_ctx = new FrameContext();
        const frame_ctx_shadow = new FrameContext();
        frame_ctx.game_state = game_state;
        frame_ctx_shadow.game_state = game_state;
        const sun_phi = (0.1 + 0.5) * Math.PI;
        const sun_theta = -0.60 * Math.PI;
        const t = gts.camera_blend;
        const s = 1.0 - t;
        const camera_offset = new Vec3([-2.0, -2.0, 0.0]).scaled(s).add(new Vec3([0.0, -2.0, 0.0]).scaled(t));
        const camera_dist = s * 10.0 + t * 5.0;
        frame_ctx.shader = this.shaders.get(SHADER.DEFAULT);
        frame_ctx.sun_dir = id_mat
            .rotate(-sun_phi, 1.0, 0.0, 0.0)
            .rotate(-sun_theta, 0.0, 1.0, 0.0)
            .multiply_vec3(new Vec3([0.0, 0.0, 1.0]), 0.0);
        frame_ctx.proj_mat = create_proj_mat(gl, 0.1, 100.0, canvas_height * 4 / 3, canvas_height);
        frame_ctx.view_sky_mat = id_mat
            .rotate(0.1 * Math.PI, 1.0, 0.0, 0.0);
        frame_ctx.view_mat = id_mat
            .translate(gts.pos.scaled(-1.0))
            .translate(camera_offset) // Orbit center
            .multiply(frame_ctx.view_sky_mat)
            .translate(new Vec3([0.0, 0.0, -camera_dist])); // Orbit distance
        if (true) {
            frame_ctx_shadow.shader = this.shaders.get(SHADER.SHADOW);
            frame_ctx_shadow.proj_mat = id_mat;
            const scale = 1.0 / 8.0;
            const scale_y = 1.0 / 15.0;
            frame_ctx_shadow.view_mat = id_mat
                .rotate(sun_theta, 0.0, 1.0, 0.0)
                .rotate(sun_phi, 1.0, 0.0, 0.0)
                .translate(new Vec3([0.0, 0.0, 0.0]))
                .scale([scale, scale_y, -scale]);
        }
        // Draw opaques to shadow map
        {
            const shader = this.shaders.get(SHADER.SHADOW);
            const unis = shader.uniform_locations;
            use_program(gl, shader);
            gl.activeTexture(gl.TEXTURE1);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.bindFramebuffer(gl.FRAMEBUFFER, this.fbo_shadow);
            gl.viewport(0, 0, SHADOW_MAP_RES, SHADOW_MAP_RES);
            gl.clearColor(0.0, 1.0, 0.0, 1.0);
            gl.clearDepth(1.0);
            gl.enable(gl.DEPTH_TEST);
            gl.depthFunc(gl.LEQUAL);
            gl.depthMask(true);
            gl.frontFace(gl.CW);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
            this.draw_opaques(SHADOW_MAP_RES, SHADOW_MAP_RES, frame_ctx_shadow);
            // Draw the slime girl too!
            const model_mat = id_mat
                .scale_1(0.25)
                .translate(gts.pos);
            const mv_mat = model_mat
                .multiply(frame_ctx_shadow.view_mat);
            gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, mv_mat);
            this.gltfs.get(GLTF.SLIME).bind(gl, shader);
            this.gltfs.get(GLTF.SLIME).draw(gl);
        }
        // Draw opaques to off-screen texture for refraction, then again
        // to the screen
        {
            gl.frontFace(gl.CCW);
            const shader = this.shaders.get(SHADER.DEFAULT);
            const unis = shader.uniform_locations;
            use_program(gl, shader);
            gl.uniformMatrix4fv(unis.get(UNI.SHADOW_MAT), false, frame_ctx_shadow.view_mat);
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.activeTexture(gl.TEXTURE1);
            gl.bindTexture(gl.TEXTURE_2D, this.tex_shadow_depth);
            gl.uniform1i(unis.get(UNI.SHADOW_MAP), 1);
            gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer_refract);
            gl.viewport(0, 0, 512, 512);
            gl.clearColor(1.0, 0.0, 1.0, 1.0);
            gl.clearDepth(1.0);
            gl.enable(gl.DEPTH_TEST);
            gl.depthFunc(gl.LEQUAL);
            gl.depthMask(true);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
            this.draw_opaques(512, 512, frame_ctx);
            // Draw opaques to screen
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.viewport(0, 0, canvas_width, canvas_height);
            gl.clearDepth(1.0);
            gl.enable(gl.DEPTH_TEST);
            gl.depthFunc(gl.LEQUAL);
            gl.depthMask(true);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
            this.draw_opaques(canvas_width, canvas_height, frame_ctx);
        }
        // Animate the slime
        const joint_mats = new Float32Array(16 * 32);
        const slime_model_mat = id_mat
            .scale_1(0.25)
            .rotate(-0.5 * Math.PI, 0.0, 1.0, 0.0)
            .translate(gts.pos);
        if (true) {
            const slime = this.gltfs.get(GLTF.SLIME);
            const skin = slime.model.skins[0];
            const bone_count = skin.joints.length;
            const inv_mats = Gltf.load_slice_f32(slime.model, slime.bytes, skin.inverseBindMatrices);
            const mats = Array.from({ length: bone_count }, (_, idx) => Mat4.create());
            const mats_by_node = new Map();
            for (let i = 0; i < bone_count; i++) {
                const node_idx = skin.joints[i];
                const m = Mat4.create();
                mats_by_node.set(node_idx, m);
            }
            // mats_by_node.set(13, Mat4.create().rotate(gts.pos.z(), 1.0, 0.0, 0.0));
            // I _think_ these are set up for root-first traversal already.
            // So iterate over the joints in order, multiplying their
            // local transforms out to the fingers and toes.
            for (let i = 0; i < bone_count; i++) {
                const node_idx = skin.joints[i];
                const node = slime.model.nodes[node_idx];
                const local_transform = Gltf.node_get_mat4(node);
                const new_mat = local_transform.multiply(mats_by_node.get(node_idx));
                // const new_mat = mats_by_node.get(node_idx).multiply(local_transform);
                mats_by_node.set(node_idx, new_mat);
                if (node.children != null) {
                    for (const child_idx of node.children) {
                        const existing = mats_by_node.get(child_idx);
                        // mats_by_node.set(child_idx, new_mat.multiply(existing));
                        mats_by_node.set(child_idx, existing.multiply(new_mat));
                    }
                }
            }
            for (let bone_idx = 0; bone_idx < bone_count; bone_idx++) {
                const node_idx = skin.joints[bone_idx];
                const inv_mat = Mat4.create();
                for (let i = 0; i < 16; i++) {
                    inv_mat[i] = inv_mats[bone_idx * 16 + i];
                }
                const a = mats_by_node.get(node_idx);
                const b = mats[bone_idx];
                // const m = inv_mat.multiply(a).multiply(b);
                // const m = inv_mat.multiply(b).multiply(a);
                // const m = a.multiply(inv_mat);
                const m = inv_mat.multiply(a);
                for (let i = 0; i < 16; i++) {
                    joint_mats[bone_idx * 16 + i] = m[i];
                }
            }
        }
        // Draw the slime giantess but with the refraction shader
        if (draw_slime) {
            gl.disable(gl.BLEND);
            gl.depthMask(true);
            const inv_model_mat = id_mat;
            const mv_mat = slime_model_mat
                .multiply(frame_ctx.view_mat);
            const obj_space_sun = inv_model_mat.multiply_vec3(frame_ctx.sun_dir, 0.0);
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, this.texture_refract);
            gl.frontFace(gl.CW);
            const shader = this.shaders.get(SHADER.SLIME);
            const unis = shader.uniform_locations;
            use_program(gl, shader);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_EYE), [0.0, 0.0, 0.0]);
            gl.uniform1f(unis.get(UNI.FULLBRIGHT), 1.0);
            gl.uniform1f(unis.get(UNI.MAX_BRIGHT), 1.0);
            gl.uniform1i(unis.get(UNI.SAMPLER), 0);
            gl.uniform1f(unis.get(UNI.USE_UV_COORDS), 0.0);
            gl.uniform1f(unis.get(UNI.USE_REFRACTION_COORDS), 1.0);
            gl.uniformMatrix4fv(unis.get(UNI.JOINT_MAT), false, joint_mats);
            gl.uniformMatrix4fv(unis.get(UNI.PROJ_MAT), false, frame_ctx.proj_mat);
            gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, mv_mat);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 1.0, 0.0]);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), obj_space_sun);
            this.gltfs.get(GLTF.SLIME).bind(gl, shader);
            this.gltfs.get(GLTF.SLIME).draw(gl);
        }
        gl.frontFace(gl.CCW);
        // Draw ingested opaques inside the slime
        if (gts.digesting != null) {
            const d = gts.digesting;
            const gltf = this.gltfs.get(d.renderable.gltf);
            gl.disable(gl.BLEND);
            const shader = this.shaders.get(SHADER.DEFAULT);
            const unis = shader.uniform_locations;
            use_program(gl, shader);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_EYE), [0.0, 0.0, 0.0]);
            gl.uniform1f(unis.get(UNI.FULLBRIGHT), 0.0);
            gl.uniform1f(unis.get(UNI.MAX_BRIGHT), 1.0);
            gl.bindTexture(gl.TEXTURE_2D, this.textures.get(d.renderable.texture));
            gltf.bind(gl, shader);
            const model_mat = id_mat
                // Scale 'n' rotate
                .multiply(d.renderable.base_transform)
                .scale_1(Math.sqrt(d.timer / d.timer_max))
                .rotate(0.25 * Math.PI, 0.0, 1.0, 0.0)
                // Move up into the slime's stomach
                .translate(new Vec3([0.0, 7.0 * 0.25, 0.0]))
                // Move along with the slime
                .translate(gts.pos);
            const inv_model_mat = id_mat.rotate(-0.25 * Math.PI, 0.0, 1.0, 0.0);
            const mv_mat = model_mat.multiply(frame_ctx.view_mat);
            const obj_space_sun = inv_model_mat.multiply_vec3(frame_ctx.sun_dir, 0.0);
            gl.uniformMatrix4fv(unis.get(UNI.MODEL_MAT), false, model_mat);
            gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, mv_mat);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 1.0, 0.0]);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), obj_space_sun);
            gltf.draw(gl);
        }
        // Draw billboards inside the slime
        if (draw_billboards) {
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.BUBBLE));
            this.particle_pass(frame_ctx, this.bubbles.length, 0.0625, (i) => {
                if (i >= gts.bubbles) {
                    return [false, new Vec3(), 0.0];
                }
                let pos = new Vec3(this.bubbles[i]);
                pos[0] = pos[0] * 0.5;
                pos[1] = pos[1] + 7.0 * 0.25;
                pos[2] = pos[2] * 0.25;
                return [true, pos.add(gts.pos), 1.0];
            });
        }
        // Draw the slime girl's front faces
        if (draw_slime) {
            gl.enable(gl.BLEND);
            gl.depthMask(true);
            const inv_model_mat = id_mat;
            const mv_mat = slime_model_mat
                .multiply(frame_ctx.view_mat);
            const obj_space_sun = inv_model_mat.multiply_vec3(frame_ctx.sun_dir, 0.0);
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, this.texture_refract);
            gl.enable(gl.BLEND);
            gl.blendFunc(gl.SRC_COLOR, gl.ONE_MINUS_SRC_ALPHA);
            gl.frontFace(gl.CCW);
            const shader = this.shaders.get(SHADER.SLIME_FRONT);
            const unis = shader.uniform_locations;
            use_program(gl, shader);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_EYE), [0.0, 0.0, 0.0]);
            gl.uniform1f(unis.get(UNI.FULLBRIGHT), 1.0);
            gl.uniform1f(unis.get(UNI.MAX_BRIGHT), 1.0);
            gl.uniform1i(unis.get(UNI.SAMPLER), 0);
            gl.uniform1f(unis.get(UNI.USE_UV_COORDS), 0.0);
            gl.uniform1f(unis.get(UNI.USE_REFRACTION_COORDS), 1.0);
            gl.uniformMatrix4fv(unis.get(UNI.JOINT_MAT), false, joint_mats);
            gl.uniformMatrix4fv(unis.get(UNI.PROJ_MAT), false, frame_ctx.proj_mat);
            gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, mv_mat);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 1.0, 0.0]);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), obj_space_sun);
            this.gltfs.get(GLTF.SLIME).bind(gl, shader);
            this.gltfs.get(GLTF.SLIME).draw(gl);
        }
        // Draw particles outside the slime
        if (true) {
            gl.activeTexture(gl.TEXTURE0);
            // Blood
            {
                gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.BLOOD));
                const particles = new Array();
                const opacities = new Array();
                for (const [ent, blood] of [...game_state.menu.ecs.bloods]) {
                    const p = game_state.menu.ecs.physics.get(ent);
                    particles.push(p.pos);
                    const frames_left = blood.live_until_frame - game_state.menu.ecs.frame;
                    opacities.push(Math.min(1.0, frames_left / 30.0));
                }
                this.particle_pass(frame_ctx, particles.length, 0.0625, (i) => {
                    return [true, particles[i], opacities[i]];
                });
            }
            // Explosions
            {
                gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.EXPLOSION));
                const particles = new Array();
                const opacities = new Array();
                for (const [ent, expl] of [...game_state.menu.ecs.explosions]) {
                    const p = game_state.menu.ecs.physics.get(ent);
                    particles.push(p.pos);
                    const frames_left = expl.live_until_frame - game_state.menu.ecs.frame;
                    opacities.push(Math.min(1.0, frames_left / 15.0));
                }
                this.particle_pass(frame_ctx, particles.length, 1.0, (i) => {
                    return [true, particles[i], opacities[i]];
                });
            }
            // UI text and such
            if (true) {
                gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.FONT));
                const s = "HP: XXXXXXXXX---";
                for (let i = 0; i < s.length; i++) {
                    const c = s.charCodeAt(i);
                    this.billboards.set_on(i, true);
                    const l = 30 + 20 * i;
                    const r = l + 20;
                    const t = 600 - 30;
                    const b = t - 24;
                    this.billboards.set_char(i, l, t, r, b, c, 1.0);
                }
                this.text_pass(frame_ctx, s.length);
                for (const [ent, tofu] of [...game_state.menu.ecs.tofu_troopers]) {
                    const s = "Stop her!";
                    const text_width = s.length * 10;
                    const p = game_state.menu.ecs.physics.get(ent);
                    let pos = [p.pos[0], p.pos[1] + 0.375, p.pos[2], 1.0];
                    pos = frame_ctx.view_mat.multiply_vec4(pos);
                    pos = frame_ctx.proj_mat.multiply_vec4(pos);
                    const x = Math.round(pos[0] * 800 * 0.5 / pos[3] + 400 - text_width * 0.5);
                    const y = Math.round(pos[1] * 600 * 0.5 / pos[3] + 300);
                    for (let i = 0; i < s.length; i++) {
                        const c = s.charCodeAt(i);
                        this.billboards.set_on(i, true);
                        const l = x + 10 * i;
                        const r = l + 10;
                        const t = y + 12;
                        const b = y;
                        this.billboards.set_char(i, l, t, r, b, c, 1.0);
                    }
                    this.text_pass(frame_ctx, s.length);
                }
            }
        }
        // Do stuff in the 2D context
        if (false) {
            const scale = 1.0;
            ctx_2d.resetTransform();
            ctx_2d.scale(scale, scale);
            ctx_2d.clearRect(0, 0, 800, 600);
            ctx_2d.textAlign = "center";
            ctx_2d.fillStyle = "#fff";
            ctx_2d.font = "bold 32px sans-serif";
            ctx_2d.fillText("Strawvery Jam 08", 400, 100);
            ctx_2d.font = "16px sans-serif";
            const buttons = [
                {
                    x: 400,
                    text: "Play",
                },
            ];
            const y = 500;
            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                let bg_color = "#00000080";
                let fg_color = "#ffffffff";
                if (i == 0) {
                    bg_color = "#ffffffff";
                    fg_color = "#000";
                }
                ctx_2d.fillStyle = bg_color;
                ctx_2d.fillRect(button.x - 75, y - 20, 150, 40);
                ctx_2d.fillStyle = fg_color;
                ctx_2d.fillText(button.text, button.x, y + 5);
            }
            ctx_2d.fillStyle = "#ffffffff";
            ctx_2d.fillText("Controls: ???", 400, 550);
        }
    }
    particle_pass(frame_ctx, count, radius, func) {
        const gl = this.gl;
        gl.enable(gl.BLEND);
        // I didn't do premul alpha, forgive me Captain D
        // GIMP was fighting me
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        gl.depthMask(false);
        gl.enable(gl.DEPTH_TEST);
        const shader = this.shaders.get(SHADER.BILLBOARD);
        const unis = shader.uniform_locations;
        use_program(gl, shader);
        const model_view_mat = id_mat.multiply(frame_ctx.view_mat);
        gl.uniformMatrix4fv(unis.get(UNI.PROJ_MAT), false, frame_ctx.proj_mat);
        gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, model_view_mat);
        gl.uniform2f(unis.get(UNI.RADIUS), radius, -radius);
        gl.uniform1i(unis.get(UNI.SAMPLER), 0);
        this.billboards.bind(gl, shader);
        for (let i = 0; i < count; i++) {
            const [on, pos, opacity] = func(i);
            this.billboards.set_on(i, on);
            if (on) {
                this.billboards.set_pos(i, pos, opacity);
                this.billboards.set_uv(i, 0, 0, 1, 1);
            }
        }
        this.billboards.upload(gl);
        this.billboards.draw(gl, count);
    }
    text_pass(frame_ctx, count) {
        const gl = this.gl;
        gl.enable(gl.BLEND);
        // I didn't do premul alpha, forgive me Captain D
        // GIMP was fighting me
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.depthMask(false);
        gl.disable(gl.DEPTH_TEST);
        const shader = this.shaders.get(SHADER.TEXT);
        const unis = shader.uniform_locations;
        use_program(gl, shader);
        gl.uniform1i(unis.get(UNI.SAMPLER), 0);
        this.billboards.bind(gl, shader);
        this.billboards.upload(gl);
        this.billboards.draw(gl, count);
    }
    draw_opaques(canvas_width, canvas_height, frame_ctx) {
        const gl = this.gl;
        const scale = 1.0;
        const shader = frame_ctx.shader;
        const unis = shader.uniform_locations;
        use_program(gl, shader);
        gl.uniformMatrix4fv(unis.get(UNI.PROJ_MAT), false, frame_ctx.proj_mat);
        gl.uniform1i(unis.get(UNI.SAMPLER), 0);
        gl.uniform1f(unis.get(UNI.MAX_BRIGHT), 1.0);
        gl.disable(gl.DEPTH_TEST);
        {
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, this.textures.get(TEXTURE.SKY));
            gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, id_mat
                .rotate(Math.PI, 1.0, 0.0, 0.0)
                .multiply(frame_ctx.view_sky_mat));
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), [0.0, 0.0, 0.0]);
            gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 0.0, 0.0]);
            gl.uniform1f(unis.get(UNI.FULLBRIGHT), 1.0);
            const gltf = this.gltfs.get(GLTF.SKY);
            gltf.bind(gl, shader);
            // gltf.draw(gl);
        }
        gl.enable(gl.DEPTH_TEST);
        // New HOT tscn scene!
        if (true) {
            gl.uniform1f(unis.get(UNI.FULLBRIGHT), 0.0);
            // The nodes happen to be sorted by resource already which is tight
            let bound_instance = null;
            let bound_gltf = null;
            const ecs = frame_ctx.game_state.menu.ecs;
            for (const [_ent, r] of [...ecs.renderables]) {
                const gltf = this.gltfs.get(r.gltf);
                if (gltf === undefined) {
                    println(`Undefined gltf ${r.gltf}`);
                }
                gltf.bind(gl, shader);
                gl.activeTexture(gl.TEXTURE0);
                if (!this.textures.has(r.texture)) {
                    println(`Missing texture ${r.texture}`);
                }
                gl.bindTexture(gl.TEXTURE_2D, this.textures.get(r.texture));
                const model_view_mat = r.transform.multiply(frame_ctx.view_mat);
                const obj_space_sun = r.transform.inverted().multiply_vec3(frame_ctx.sun_dir, 0.0);
                gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), obj_space_sun);
                gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 1.0, 0.0]);
                gl.uniformMatrix4fv(unis.get(UNI.MODEL_MAT), false, r.transform);
                gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, model_view_mat);
                gltf.draw(gl);
            }
            const scene = this.scene;
            for (const node of scene.nodes) {
                const inst = node.instance;
                if (bound_instance == null || bound_instance != inst) {
                    let gltf = null;
                    let tex = null;
                    if (inst == "3_c8fpb") {
                        gltf = GLTF.CLIFFSIDE;
                        tex = TEXTURE.CLIFFSIDE;
                    }
                    else {
                        //throw new Error(`Unsupported resource ${inst}`);
                        continue;
                    }
                    bound_gltf = this.gltfs.get(gltf);
                    if (bound_gltf === undefined) {
                        throw new Error(`didn't load glTF ${gltf}`);
                    }
                    bound_gltf.bind(gl, shader);
                    gl.activeTexture(gl.TEXTURE0);
                    gl.bindTexture(gl.TEXTURE_2D, this.textures.get(tex));
                }
                const model_view_mat = node.transform.multiply(frame_ctx.view_mat);
                const obj_space_sun = node.light_transform.multiply_vec3(frame_ctx.sun_dir, 0.0);
                gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SUN), obj_space_sun);
                gl.uniform3fv(unis.get(UNI.OBJ_SPACE_SKY), [0.0, 1.0, 0.0]);
                gl.uniformMatrix4fv(unis.get(UNI.MODEL_MAT), false, node.transform);
                gl.uniformMatrix4fv(unis.get(UNI.MV_MAT), false, model_view_mat);
                bound_gltf.draw(gl);
            }
        }
    }
}
