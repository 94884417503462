var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GameState } from "./states";
import { Graphics } from "./graphics";
import { println } from "./print";
import { load_resources } from "./resources";
import "./howler.min";
import "./tauri_stub";
import { appWindow, LogicalSize } from "@tauri-apps/api/window";
const invoke = window.__TAURI__.tauri.invoke;
invoke("get_clap_args")
    .then((clap_args) => {
    appWindow.setSize(new LogicalSize(clap_args.width, clap_args.height));
})
    .catch((e) => {
    console.warn(e);
});
import { RsGamepad } from "./gamepad";
const canvas_element = document.getElementById("QR4YH2UP");
const canvas_element_2d = document.getElementById("GJ2STGZB");
const canvas_div = document.getElementById("XEOUIAQP");
const gl = canvas_element.getContext('webgl2');
let graphics = null;
const ctx_2d = canvas_element_2d.getContext("2d");
let canvas_width = canvas_element.width;
let canvas_height = canvas_element.height;
let raf_id = null;
let last_ms = null;
let fps_num = 60;
let fps_den = 1000;
let timestep_accum = fps_den / 2;
let running = false;
let gamepad = new RsGamepad();
canvas_element_2d.addEventListener("keydown", event => {
    if (event.isComposing || event.keyCode === 229) {
        return;
    }
    gamepad.keydown(event.code);
    event.preventDefault();
});
canvas_element_2d.addEventListener("keyup", event => {
    if (event.isComposing || event.keyCode === 229) {
        return;
    }
    gamepad.keyup(event.code);
    event.preventDefault();
});
class TheGame {
    constructor() {
        this.frame_count = 0;
        this.last_fps_tick = performance.now();
    }
    main() {
        return __awaiter(this, void 0, void 0, function* () {
            const resources = yield load_resources();
            this.game_state = new GameState(resources);
            this.graphics = new Graphics(gl, resources);
            yield this.graphics.load_textures(gl, resources);
            this.draw();
            const that = this;
            canvas_element_2d.addEventListener("blur", event => {
                gamepad.clear();
                that.pause();
            });
            canvas_element_2d.addEventListener("focus", event => {
                gamepad.clear();
                that.play();
            });
        });
    }
    play() {
        canvas_element.focus();
        set_running(true);
        cancel_raf();
        const that = this;
        raf_id = window.requestAnimationFrame((x) => that.step(x));
    }
    pause() {
        set_running(false);
        this.draw();
    }
    draw() {
        this.frame_count += 1;
        const mpf_interval = 60.0;
        if (this.frame_count >= mpf_interval) {
            this.frame_count -= mpf_interval;
            const now = performance.now();
            const diff = now - this.last_fps_tick;
            this.last_fps_tick = now;
            const mpf = diff / mpf_interval;
            const phys_mpf = this.game_state.menu.phys_mpf / mpf_interval;
            this.game_state.menu.phys_mpf = 0.0;
            const phys_count = this.game_state.menu.ecs.physics.size;
            println(`mpf: ${mpf.toFixed(1)}, phys: ${phys_mpf.toFixed(2)}, ${phys_count}`);
        }
        this.graphics.draw_scene(this.game_state, ctx_2d, canvas_width, canvas_height);
    }
    resize_canvas(width, height) {
        canvas_element.width = width;
        canvas_element.height = height;
        canvas_element_2d.width = width;
        canvas_element_2d.height = height;
        canvas_div.style.width = String(width) + "px";
        canvas_div.style.height = String(height) + "px";
        canvas_width = width;
        canvas_height = height;
        this.draw();
    }
    step(now_ms) {
        if (last_ms == null) {
            last_ms = now_ms;
        }
        const delta_ms = now_ms - last_ms;
        // println(`delta_ms = ${delta_ms}`);
        timestep_accum += delta_ms * fps_num;
        let logic_steps = 0;
        while (timestep_accum >= fps_den) {
            logic_steps += 1;
            timestep_accum -= fps_den;
        }
        if (logic_steps > 3) {
            logic_steps = 0;
        }
        last_ms = now_ms;
        for (let i = 0; i < logic_steps; i++) {
            this.fixed_step();
        }
        this.draw();
        if (running) {
            const that = this;
            raf_id = window.requestAnimationFrame((x) => that.step(x));
        }
    }
    fixed_step() {
        this.game_state.step(gamepad, running);
        gamepad.tick();
    }
}
function set_running(b) {
    running = b;
}
function cancel_raf() {
    timestep_accum = fps_den / 2;
    last_ms = null;
    if (raf_id != null) {
        window.cancelAnimationFrame(raf_id);
        raf_id = null;
    }
}
const the_game = new TheGame();
the_game.main().catch(err => println(`Error in the game: ${err.message}`));
