import { ATTR } from "./consts";
import { Mat4, Vec3 } from "./matrix";
import { println } from "./print";
export function node_get_mat4(node) {
    let m = Mat4.create();
    // XYZW
    const r = node.rotation;
    if (r != null) {
        const [x, y, z, w] = r;
        const magic = Math.sqrt(x * x + y * y + z * z);
        m = m.rotate(2 * Math.atan2(magic, w), x / magic, y / magic, z / magic);
    }
    const s = node.scale;
    if (s != null) {
        m = m.scale(s);
    }
    const t = node.translation;
    if (t != null) {
        m = m.translate(t);
    }
    return m;
}
function load_slice_u8(model, bytes, idx) {
    const a = model.accessors[idx];
    if (a.componentType != 5121) {
        throw new Error("Cannot load u8 slice from non-u8 accessor");
    }
    const v = model.bufferViews[a.bufferView];
    const b = bytes.slice(v.byteOffset, v.byteOffset + v.byteLength);
    if (b.byteLength != v.byteLength) {
        throw new Error(`load_slice_u8 size mismatch ${b.byteLength} != ${v.byteLength}`);
    }
    return new Uint8Array(b);
}
export function load_slice_f32(model, bytes, idx) {
    const a = model.accessors[idx];
    if (a.componentType != 5126) {
        throw new Error("Cannot load f32 slice from non-f32 accessor");
    }
    const v = model.bufferViews[a.bufferView];
    const b = bytes.slice(v.byteOffset, v.byteOffset + v.byteLength);
    if (b.byteLength != v.byteLength) {
        throw new Error(`load_slice_f32 size mismatch ${b.byteLength} != ${v.byteLength}`);
    }
    try {
        const s = new Float32Array(b);
        return s;
    }
    catch (err) {
        throw new Error(`load_slice_f32 failed for ${idx}, ${v.byteOffset}, ${v.byteLength}: ${err}`);
    }
}
export class Renderable {
    constructor(gl, model, bytes) {
        const imported = new Import(model, bytes);
        this.model = model;
        this.bytes = bytes;
        this.vertex_buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertex_buffer);
        this.index_buffer = gl.createBuffer();
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.index_buffer);
        // Dump our CPU arrays into OpenGL
        gl.bufferData(gl.ARRAY_BUFFER, imported.vertex_data, gl.STATIC_DRAW);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, imported.index_data, gl.STATIC_DRAW);
        this.vertex_count = imported.vert_cursor;
        this.index_count = imported.index_data.length;
    }
    bind(gl, shader) {
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertex_buffer);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.index_buffer);
        const stride = 4 * (3 + 3 + 2);
        for (const a of [
            { attr: ATTR.POS, count: 3, ofs: 4 * 0 },
            { attr: ATTR.NORM, count: 3, ofs: 4 * (3) },
            { attr: ATTR.UV, count: 2, ofs: 4 * (3 + 3) },
        ]) {
            const loc = shader.attrib_locations.get(a.attr);
            if (loc != -1) {
                gl.vertexAttribPointer(loc, a.count, gl.FLOAT, false, stride, a.ofs);
            }
        }
        const loc_weights = shader.attrib_locations.get(ATTR.WEIGHTS);
        const loc_joints = shader.attrib_locations.get(ATTR.JOINTS);
        if (loc_weights != -1) {
            gl.vertexAttribPointer(loc_weights, 4, gl.FLOAT, false, 0, stride * this.vertex_count);
        }
        if (loc_joints != -1) {
            gl.vertexAttribPointer(loc_joints, 4, gl.UNSIGNED_BYTE, false, 0, stride * this.vertex_count + 4 * 4 * this.vertex_count);
        }
    }
    draw(gl) {
        gl.drawElements(gl.TRIANGLES, this.index_count, gl.UNSIGNED_SHORT, 0);
    }
}
class SkinImport {
}
// Class for importing one glTF
class Import {
    constructor(model, bytes) {
        // https://github.com/KhronosGroup/glTF/blob/main/specification/2.0/figures/gltfOverview-2.0.0d.png
        // Every node has one mesh
        // Every mesh has one prim
        // Every prim has indices and 3 vertex attributes
        // indices and attributes are both accessors of bufferViews
        this.idx_cursor = 0;
        this.vert_cursor = 0;
        // Iterate over every prim and allocate space
        this.model = model;
        this.bytes = bytes;
        let vertex_count = 0;
        let index_count = 0;
        let is_skinned = false;
        for (const node of model.nodes) {
            if (node.mesh == null) {
                continue;
            }
            const mesh = model.meshes[node.mesh];
            const prim = mesh.primitives[0];
            if (prim.attributes.JOINTS_0 != null) {
                is_skinned = true;
            }
            const idxs = model.accessors[prim.indices];
            index_count += idxs.count;
            const pos = model.accessors[prim.attributes.POSITION];
            vertex_count += pos.count;
        }
        let vertex_bytes = vertex_count * 4 * (3 + 3 + 2);
        if (is_skinned) {
            vertex_bytes += vertex_count * 4 * (4 + 1);
        }
        this.vertex_data = new ArrayBuffer(vertex_bytes);
        this.index_data = new Uint16Array(index_count);
        if (is_skinned) {
            println("Loaded skinned mesh");
            const skin = new SkinImport();
            skin.weights = new Float32Array(this.vertex_data, vertex_count * 4 * (3 + 3 + 2), vertex_count * 4);
            skin.joints = new Uint8Array(this.vertex_data, vertex_count * 4 * (3 + 3 + 2 + 4), vertex_count * 4);
            this.skin = skin;
        }
        for (const node of model.nodes) {
            this.add_node(node);
        }
    }
    add_node(node) {
        if (node.mesh == null) {
            // Empty node
            return;
        }
        const vd = new Float32Array(this.vertex_data);
        const model = this.model;
        const accessors = this.model.accessors;
        const bytes = this.bytes;
        const m = node_get_mat4(node);
        const mesh = model.meshes[node.mesh];
        const prim = mesh.primitives[0];
        {
            const idxs = this.model.bufferViews[accessors[prim.indices].bufferView];
            const slice = new Uint16Array(bytes.slice(idxs.byteOffset, idxs.byteOffset + idxs.byteLength));
            for (let i = 0; i < slice.length; i++) {
                this.index_data[this.idx_cursor + i] = slice[i] + this.vert_cursor;
            }
            this.idx_cursor += slice.length;
        }
        {
            const attrs = prim.attributes;
            // Vertexes in this prim
            const vert_count = accessors[attrs.POSITION].count;
            const pos_slice = load_slice_f32(model, bytes, attrs.POSITION);
            const norm_slice = load_slice_f32(model, bytes, attrs.NORMAL);
            const uv_slice = load_slice_f32(model, bytes, attrs.TEXCOORD_0);
            let weight_slice = null;
            let joint_slice = null;
            if (this.skin != null) {
                weight_slice = load_slice_f32(model, bytes, attrs.WEIGHTS_0);
                joint_slice = load_slice_u8(model, bytes, attrs.JOINTS_0);
            }
            for (let i = 0; i < vert_count; i++) {
                const offset = this.vert_cursor * (3 + 3 + 2);
                {
                    const pos = new Vec3([
                        pos_slice[i * 3 + 0],
                        pos_slice[i * 3 + 1],
                        pos_slice[i * 3 + 2],
                    ]);
                    const xyz = m.multiply_vec3(pos, 1.0);
                    vd[offset + 0] = xyz[0];
                    vd[offset + 1] = xyz[1];
                    vd[offset + 2] = xyz[2];
                }
                {
                    const norm = new Vec3([
                        norm_slice[i * 3 + 0],
                        norm_slice[i * 3 + 1],
                        norm_slice[i * 3 + 2],
                    ]);
                    const xyz = m.multiply_vec3(norm, 0.0);
                    vd[offset + 3] = xyz[0];
                    vd[offset + 4] = xyz[1];
                    vd[offset + 5] = xyz[2];
                }
                vd[offset + 6] = uv_slice[i * 2 + 0];
                vd[offset + 7] = uv_slice[i * 2 + 1];
                if (this.skin != null) {
                    for (let j = 0; j < 4; j++) {
                        this.skin.joints[this.vert_cursor * 4 + j] = joint_slice[i * 4 + j];
                        this.skin.weights[this.vert_cursor * 4 + j] = weight_slice[i * 4 + j];
                    }
                }
                this.vert_cursor += 1;
            }
        }
    }
}
